/**
 * This function takes a json and populates form field values from it
 * @param JSON data - form data
 */
export function populateForm(data) {
    let integration = '';
    const questions = [];
    Object.keys(data).forEach((key) => {
        if (key === 'PMS') {
            integration = data[key].value[0] || '';
        }
        const element = data[key];
        let input;
        switch (element.type) {
            case 'input':
            case 'textarea':
                input = document.querySelector(`[name="${key}"]`);
                if (input) {
                    input.value = element.value;
                }
                if (element.value.length > 0) {
                    questions.push(key);
                }
                break;

            case 'checkbox':
            case 'radio':
                element.value.forEach((answer) => {
                    input = document.querySelector(
                        `[name="${key}"][value="${answer}"]`,
                    );
                    if (input) {
                        input.checked = true;
                    }
                });
                if (element.value.length > 0) {
                    questions.push(key);
                }
                break;

            case 'scale':
                const ratings = element.value?.ratings;
                if (ratings) {
                    Object.entries(element.value.ratings).forEach(rating => {
                        const name = rating[0];
                        const value = rating[1];
                        console.log(name, value);
                        input = document.querySelector(
                            `[name="${name}"][value="${value}"]`,
                        );
                        if (input) {
                            input.checked = true;
                        }
                    });
                    if (element.value?.isValid) {
                        questions.push(key);
                    }
                }
                break;

            case 'readings':
                input = document.getElementById(key);
                if (input) {
                    input.checked = element.value;
                }
                if (element.value) {
                    questions.push(key);
                }
                break;
            
            case 'heading':
                break;
        }
    });
    return {questions, integration};
}

/**
 * This function takes a json and populates the evaluation form
 * @param JSON data - form data
 */
export function populateEvaluation(data) {
    const questions = {};
    Object.keys(data).forEach((key) => {
        const question = data[key];
        switch (key) {
            case 'Full name':
                const fullNameSelector = document.querySelector(
                    `[name="Full name"]`,
                );
                if (fullNameSelector && question.value) {
                    questions[key] = question;
                    fullNameSelector.value = question.value;
                }
                break;

            case 'RACGP number':
                const racgpSelector = document.querySelector(
                    '[name="RACGP number"]',
                );
                if (racgpSelector && question.value) {
                    questions[key] = question;
                    racgpSelector.value = question.value;
                }
                break;

            default:
                break;
        }
    });
    return questions;
}

/**
 * Checks if dom storage is available by attempting to set and remove a test item. If this fails than storage is not available.
 * @return {boolean} If storage is available or not
 */
export function storageIsAvailable() {
    try {
        localStorage; //eslint-disable-line
    } catch (error) {
        return false;
    }
    return true;
}

/**
 * Sets a property on a local storage object
 * @param string name - name of local storage variable
 * @param string property - name of property
 * @param any value
 */
export function setStorageProperty(name, property, value) {
    if (storageIsAvailable()) {
        const data = localStorage.getItem(name);
        if (data) {
            const parsedData = JSON.parse(data);
            parsedData[property] = value;
            localStorage.setItem(name, JSON.stringify(parsedData));
        } else {
            localStorage.setItem(name, JSON.stringify({[property]: value}));
        }
        const event = new Event('racgp_change');
        window.dispatchEvent(event);
    }
}

export function slugify(string) {
    return string
        .toLowerCase()
        .replace(/^\s+|\s+$/g, '') // trim
        .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // swap whitespace for hyphen
        .replace(/-+/g, '-'); // collapse dashes
}
