import React from 'react';
import PropTypes from 'prop-types';

import Graphic from 'components/Graphic.js';
import {setStorageProperty} from 'src/utils.js';

export default class QuestionScale extends React.Component {
    static propTypes = {
        answers: PropTypes.array,
        name: PropTypes.string.isRequired,
        scale: PropTypes.array,
        validate: PropTypes.func,
    };

    constructor() {
        super();
        this.handleChange = this.handleChange.bind(this);
        this.scaleRef = React.createRef();
    }

    handleChange() {
        const {answers, name, validate} = this.props;
        const ratings = this.getRatings();

        let isValid = Object.values(ratings).length >= answers.length;
        validate(name, isValid);

        setStorageProperty('racgp-course', name, {
            value: {ratings, isValid},
            type: 'scale',
        });
    }

    getRatings() {
        const scaleLength = this.props.scale.length;
        const values = {};
        const inputs = Array.from(document.querySelectorAll('.rating input'));
        for (let i = 0; i < inputs.length; i += scaleLength) {
            let name = '';
            let value;
            inputs.slice(i, i + scaleLength).forEach((input) => {
                name = input.name;
                if (input.checked) {
                    value = input.value
                }
            });
            if (value) {
                values[name] = value;
            }
        }
        return values;
    }

    render() {
        const {
            answers,
            scale
        } = this.props;
        return (
            <div className="ratings" ref={this.scaleRef}>
                <div className="scale">
                    {scale.map((scaleName, i) => (<p key={`${scaleName}-${i}`}>{i + 1}{' = '}{scaleName}</p>))}
                </div>
                {answers.map((answer, i) => (
                    <div className="rating" key={`${answer}-${i}`}>
                        <label>{answer}</label>
                        {scale.map((s, si) => (
                            <label key={`${s}-${si}`}>
                                <input
                                    name={`${this.props.name}-${answer}`}
                                    onChange={this.handleChange}
                                    type={'radio'}
                                    value={scale[si]}
                                />
                                <Graphic
                                    name={'radio'}
                                />
                                <em>{si + 1}</em>
                            </label>
                        ))}
                    </div>
                ))}
            </div>
        );
    }
}
