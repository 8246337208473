import React from 'react';
import Markdown from 'react-markdown';
import PropTypes from 'prop-types';

import QuestionAnswers from 'components/QuestionAnswers.js';
import QuestionScale from 'components/QuestionScale.js';
import {setStorageProperty} from 'src/utils.js';
import {slugify} from '../utils.js';

export default class Question extends React.Component {
    static propTypes = {
        answers: PropTypes.array,
        correctAnswer: PropTypes.number,
        hidden: PropTypes.bool,
        id: PropTypes.string,
        integration: PropTypes.string,
        label: PropTypes.string.isRequired,
        multiple: PropTypes.bool,
        requiresIntegration: PropTypes.string,
        subQuestions: PropTypes.object,
        tab: PropTypes.number,
        type: PropTypes.string,
        validate: PropTypes.func,
    };

    constructor() {
        super();
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const {validate} = this.props;
        const {name, value} = event.target;

        validate(name, value.length > 0);

        setStorageProperty('racgp-course', name, {
            value,
            type: this.props.type === 'long' ? 'textarea' : 'input',
        });
    }

    render() {
        const {
            answers,
            correctAnswer,
            hidden,
            id,
            integration,
            label,
            multiple,
            subQuestions,
            tab,
            type,
            validate,
            requiresIntegration,
            scale
        } = this.props;
        const name = tab ? `Patient ${tab}: ${label}` : label;

        if (requiresIntegration && requiresIntegration !== integration) {
            return null;
        }

        return (
            <div hidden={hidden} className={type}>
                <label id={slugify(id ? id : name)}>
                    <Markdown
                        disallowedElements={['p']}
                        linkTarget="_blank"
                        unwrapDisallowed
                    >
                        {label}
                    </Markdown>
                    {answers && multiple && (
                        <em>{' (Tick all that apply)'}</em>
                    )}
                    {type === 'long' && (
                        <textarea name={name} onChange={this.handleChange} />
                    )}
                    {!type && !answers && !subQuestions && (
                        <input
                            name={id ? id : name}
                            onChange={this.handleChange}
                            type="text"
                        />
                    )}
                </label>
                {answers && (
                    type === 'scale' ?
                    <QuestionScale
                        answers={answers}
                        name={id ? id : name}
                        scale={scale}
                        validate={validate}
                    /> :
                    <QuestionAnswers
                        answers={answers}
                        correctAnswer={correctAnswer}
                        multiple={multiple}
                        name={id ? id : name}
                        validate={validate}
                    />
                )}
                {subQuestions && (
                    <QuestionAnswers
                        answers={Object.keys(subQuestions)}
                        name={id ? id : name}
                        subQuestions={Object.values(subQuestions)}
                        validate={validate}
                    />
                )}
            </div>
        );
    }
}
