import React from 'react';

import Layout from 'components/Layout.js';
import Question from 'components/Question.js';

import evaluation from 'data/evaluation.json';
import {populateEvaluation, storageIsAvailable} from '../utils';

export default class View extends React.Component {
    constructor() {
        super();
        const incomplete = [];

        evaluation.questions.forEach((question) => {
            if (question.required) {
                incomplete.push(question.label);
            }
        });

        this.state = {incomplete};

        this.handleSubmit = this.handleSubmit.bind(this);
        this.validate = this.validate.bind(this);
    }

    componentDidMount() {
        if (storageIsAvailable()) {
            const data = localStorage.getItem('racgp-course');
            if (data) {
                const parsedData = JSON.parse(data);
                const questions = populateEvaluation(parsedData);
                Object.keys(questions).forEach((question) => {
                    this.validate(question, true);
                });
            }
        }
    }

    handleSubmit(event) {
        const {incomplete} = this.state;

        if (incomplete.length > 0) {
            event.preventDefault();
            // eslint-disable-next-line no-alert
            alert('Please ensure you have answered every question.');
        }
    }

    validate(question, valid) {
        const {incomplete} = this.state;

        if (valid && incomplete.includes(question)) {
            incomplete.splice(incomplete.indexOf(question), 1);
        }

        this.setState({incomplete});
    }

    render() {
        return (
            <Layout className="racgp">
                <form
                    action="/contact/thank-you/"
                    data-netlify="true"
                    method="post"
                    name="RACGP course evaluation"
                    netlify-honeypot="bot-field"
                    onSubmit={this.handleSubmit}
                >
                    <input
                        name="form-name"
                        type="hidden"
                        value="RACGP course evaluation"
                    />
                    <input name="bot-field" type="hidden" />
                    <section className="group">
                        <section className="questions">
                            <h1>{'Thank you.'}</h1>
                            <p>
                                {
                                    'Thank you for completing this course.'
                                }
                            </p>
                            <p>
                                {
                                    'We trust it has provided you with an overview of the evolving primary care landscape, and the benefits of using various digital health tools in your day-to-day practice.'
                                }
                            </p>
                            <p>
                                {
                                    'We would appreciate it if you could please complete the evaluation form.'
                                }
                            </p>
                            {evaluation.questions.map((question, i) => (
                                <Question
                                    answers={question.answers}
                                    key={i}
                                    label={question.label}
                                    multiple={question.multiple}
                                    required={question.required}
                                    scale={question.scale}
                                    type={question.type}
                                    validate={this.validate}
                                />
                            ))}
                        </section>
                    </section>
                    <section className="group">
                        <div className="btn-group">
                            <button type="submit">
                                {'Submit evaluation'}
                            </button>
                        </div>
                    </section>
                </form>
            </Layout>
        );
    }
}
