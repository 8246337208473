import React from 'react';
import Markdown from 'react-markdown';
import PropTypes from 'prop-types';

import Graphic from 'components/Graphic.js';
import Question from 'components/Question.js';
import {setStorageProperty} from 'src/utils.js';

export default class QuestionAnswers extends React.Component {
    static propTypes = {
        answers: PropTypes.array,
        correctAnswer: PropTypes.number,
        multiple: PropTypes.bool,
        name: PropTypes.string.isRequired,
        subQuestions: PropTypes.array,
        validate: PropTypes.func,
    };

    constructor() {
        super();
        this.state = {
            selectedAnswers: [],
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleOther = this.handleOther.bind(this);
    }

    componentDidMount() {
        // setTimeout is used to make sure this DOM action is triggered after react is mounted
        setTimeout(() => {
            const selectedAnswers = [];
            this.props.answers.forEach((answer) => {
                const input = document.querySelector(`[value="${answer}"]`);
                if (input?.checked) {
                    selectedAnswers.push(answer);
                }
            });
            this.setState({
                selectedAnswers,
            });
        }, 0);
    }

    handleChange(event) {
        const {answers, multiple, name, validate} = this.props;
        const selectedAnswers = [...this.state.selectedAnswers];

        if (!multiple) {
            selectedAnswers.pop();
        }

        if (event.target.checked) {
            selectedAnswers.push(event.target.value);
        } else {
            selectedAnswers.splice(
                selectedAnswers.indexOf(event.target.value),
                1,
            );
        }

        validate(name, selectedAnswers.length > 0);

        this.setState({selectedAnswers});

        const isCheckbox = multiple || answers.length === 1;
        setStorageProperty('racgp-course', multiple ? `${name}[]` : name, {
            value: selectedAnswers,
            type: isCheckbox ? 'checkbox' : 'radio',
        });
    }

    handleOther(event) {
        const {name} = this.props;
        setStorageProperty('racgp-course', `${name} (Other)`, {
            value: event.target.value,
            type: 'textarea',
        });
    }

    render() {
        const {
            answers,
            correctAnswer,
            multiple,
            name,
            subQuestions,
            validate,
        } = this.props;
        const {selectedAnswers} = this.state;
        return (
            <>
                {answers.map((answer, i) => (
                    <label className="option" key={i}>
                        {multiple ? (
                            <input
                                name={`${name}[]`}
                                onChange={this.handleChange}
                                type="checkbox"
                                value={answer}
                            />
                        ) : (
                            <input
                                name={name}
                                onChange={this.handleChange}
                                type={
                                    answers.length === 1 ? 'checkbox' : 'radio'
                                }
                                value={answer}
                            />
                        )}
                        <Graphic
                            name={
                                multiple || answers.length === 1
                                    ? 'checkbox'
                                    : 'radio'
                            }
                        />
                        <Markdown disallowedElements={['p']} unwrapDisallowed>
                            {answer}
                        </Markdown>
                        {correctAnswer && correctAnswer === i && (
                            <b className="correct">{'Correct!'}</b>
                        )}
                        {correctAnswer && correctAnswer !== i && (
                            <b className="incorrect">{'Try again\u2026'}</b>
                        )}
                        {answer === 'Other' && (
                            <textarea
                                name={`${name} (${answer})`}
                                onChange={this.handleOther}
                            />
                        )}
                    </label>
                ))}
                {subQuestions &&
                    subQuestions.map((question, i) => (
                        <Question
                            answers={question.answers}
                            correctAnswer={question.correctAnswer}
                            hidden={!selectedAnswers.includes(answers[i])}
                            id={question.id}
                            key={i}
                            label={question.label}
                            multiple={question.multiple}
                            type={question.type}
                            validate={validate}
                        />
                    ))}
            </>
        );
    }
}
